import React, { useState, useEffect, useCallback } from 'react';
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import BackButton from "../../../images/button_back.gif";
import HomeButton from "../../../images/button_home.gif";
import Image from '../Image/Image';
import ButtonNewFolder from "../../../images/button_new_folder.gif";
import ButtonUpload from '../../../images/button_upload.gif';
import BulkUploadPopup from "../Modal/bulkUploadPopup";
import BulkDownload from "../../../images/download.gif";
import BulkDownloadPopup from "../Modal/bulkDownloadPopup";
import Modal from '../../common/Modal';
import { updateMediaServerUrlsToempty, getSubDirectories, noDirOrUserFound, deleteFileStateInfo } from '../../Dashboard/actions';
import { updateGenricSuccessMessageToEmpty, getUserList } from '../../User/actions';
import PeopleTwoToneIcon from '@mui/icons-material/PeopleTwoTone';
import TopNav from './TopNav';
import NewFolder from '../Modal/NewFolder';
 
 
 
const Banner = (props) => {
    const [bannerMessage, setBannerMessage] = useState("");
 
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [mediaServerUrl, setMediaServerUrl] = useState('');
    const [mediaServerFileName, setMediaServerFileName] = useState('');
    const [curriculumUrl, setCurriculumURL] = useState('');
    const [liveUrlSet, setLiveUrlSet] = useState(0);
    const [genericSuccessMessages, setGenericSuccessMessages] = useState('');
    const controller = new AbortController();
    const mediaServerURLs = useSelector(state => { return state.directoriesReducer.mediaServerURLs });
    const successMessage = useSelector(state => { return state.UserReducer.userSuccessMessage });
    const deletedFilesInfo = useSelector(state => { return state.directoriesReducer.fileDeleteInfo });
    const [bulkdownload, setbulkdownload] = useState(false);
    useEffect(() => {
        setMediaServerUrl(mediaServerURLs?.liveurl);
        setCurriculumURL(mediaServerURLs?.curriculum_liveurl);
        setLiveUrlSet(mediaServerURLs?.push_live_ok);
        setMediaServerFileName(mediaServerURLs?.file_name)
    }, [mediaServerURLs]);
 
    useEffect(() => {
        setGenericSuccessMessages(successMessage);
        if (successMessage.status_code === 200 && (location.pathname).includes('/user/list')) {
            dispatch(getUserList())
        }
        else if (successMessage.status_code === 200 && (location.pathname).includes('/mdc/auth/media')) {
            dispatch(getSubDirectories({ dir: props.currentPath }));
        }
    }, [successMessage, location])
 
    const navigateBack = (e) => {
        let navigateToPreviousPath = (props.currentPath).split('/');
        navigateToPreviousPath.pop();
        navigateToPreviousPath = navigateToPreviousPath.filter(path => path);
        navigateToPreviousPath = `/${navigateToPreviousPath.join('/')}`;
        if (navigateToPreviousPath !== '/' && navigateToPreviousPath !== '/user') {
            navigate(`/mdc/auth/media${navigateToPreviousPath}`);
        } else {
            navigate('/mdc/auth/media');
        }
    }
 
    const navigateToHome = (e) => {
        navigate('/mdc/auth/media');
    }
 
    const navigateToUsers = (e) => {
        navigate('/user/list');
    }
 
    let [modal, setModal] = useState(false);
    const [bulkModal, setBulkModal] = useState(false);
    const [newFolderName, setNewFolderName] = useState(false);
 
    const handleModalPopupStateChange = useCallback((data) => {
        setModal(!data);
    }, [])
 
    const handleModalBulkUploadPopupStateChange = useCallback((data, e) => {
        if ((e.target.tagName == 'SPAN' || e.target.tagName == 'BUTTON') && e.target.outerHTML.includes('close')) {
            setBulkModal(!data);
        }
        e.preventDefault();
    }, [])
 
    const selectModal = (info) => {
        navigate(`${location.pathname}?upload=1`);
        setModal(!modal);
    }
 
    const NavigateToPath = (e) => {
        let navigateURL = e.target.value;
        navigate(`/mdc/auth/media/${navigateURL}`);
    }
 
    const styles = {
        directoriesButton: {
            borderStyle: "none",
            color: "#006",
            cursor: "pointer",
            textDecoration: "underline",
            marginRight: "-6px",
            paddingLeft: "1px",
            fontWeight: "bold",
            fontSize: "12px"
        }
    }
    const customStyles = {
        content: {
            height: '246px',
            top: '-25%',
        },
        /*overlay: {
          backgroundColor: "rgba(0, 0, 0, 0)"
        },*/
    };
    let pathPieces = props.currentPath.split('/').filter(dir => dir);
    let directoriesLink = [];
    let defaultPath = '';
    pathPieces.forEach(path => {
        defaultPath = defaultPath + '/' + path;
        directoriesLink.push(<strong>/<button onClick={NavigateToPath} value={defaultPath} style={styles.directoriesButton}> {decodeURIComponent(path)}</button></strong>);
    });
 
    // when directory path is changed clean up mediaserver urls
    useEffect(() => {
        dispatch(updateMediaServerUrlsToempty({}));
        dispatch(updateGenricSuccessMessageToEmpty({}));
        dispatch(noDirOrUserFound({}))
        dispatch(deleteFileStateInfo({}))
        if ((location.pathname).includes('/mdc/auth/media')) {
            setTimeout(() => {
                dispatch(getSubDirectories({ dir: props.currentPath }));
            }, 1000)
        }
    }, [dispatch, location])
 
    const toggleUploadPopup = useCallback((info) => {
        navigate(`${location.pathname}?bulkupload=1`);
        setBulkModal(!bulkModal);
    }, [location]);
 
    const createNewFolder = (event) => {
        event.preventDefault();
        navigate(`${location.pathname}?newfolder=1`);
        setNewFolderName(!newFolderName);
    }
    const handleModalBulkDownloadPopupStateChange = useCallback((data) => {
        setbulkdownload(!data);
    }, [])
 
    const toggleDownloadPopup = useCallback((info) => {
        //dispatch(getSubDirectories({ dir: props.currentPath }))
        setbulkdownload(!bulkdownload);
    }, [location]);
 
    useEffect(() => {
        if (deletedFilesInfo?.status_code == 200 || deletedFilesInfo?.status_code == 500) {
            dispatch(getSubDirectories({ dir: props.currentPath }));
        }
    }, [deletedFilesInfo])
 
    const closeCreateFolderModalPopup = (popupStatus) => {
        setNewFolderName(!popupStatus);
    }
    useEffect(() => {
        setBannerMessage(
            <>   We are in the process of transitioning to a new system for  Cyberduck user access and upgrading to the
                <a
                    href="https://auth03.mediaconsole.pearson.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#0000EE", textDecoration: "underline" }} >{" "} pearson.com&nbsp;
                </a>
                domain. Effective immediately, all new access requests for <strong> existing Cyberduck users</strong> should be submitted through a
                <a href="https://pearsonnow.service-now.com/esc?id=sc_cat_item&table=sc_cat_item&sys_id=9336da491b8ea1d01c87ed79b04bcb85&sysparm_role_access=892fde591bae9a5062e683a5464bcbb2"
                    target="_blank"
                    rel="noopener noreferrer" style={{ color: "#0000EE", textDecoration: "underline" }} >{" "}MyHelp request.
                </a>{" "}
                Please note that we will retire the old domain during the first week of June 2025.
            </>
        );
    }, []);
 
 
    return (
        <div>
 
            <TopNav title={props.title} currentPath={props.currentPath} />
 
            <table border="0" width="99%" cellSpacing="0" cellPadding="0">
                <tr align="left" valign="top">
                    <td align="left" valign="middle" width="40%" height="70">
 
                        <table border="0" width="" cellSpacing="0" cellPadding="0">
                            <tr align="left" valign="top">
                                <td align="left" valign="top">
                                    <Image src={BackButton} alt="Back" title="Back" width="51" height="48" hspace="2" vspace="0" border="0" onClick={navigateBack} className="folderpointer" />
                                </td>
                                <td align="left" valign="top">
                                    <Image src={HomeButton} alt="Home" title="Home" width="51" height="48" hspace="2" vspace="0" border="0" onClick={navigateToHome} className="folderpointer" />
                                </td>
                                {!props.disableCheckbox &&
                                    <td align="left" valign="top"><span title="New Folder"><img src={ButtonNewFolder} alt="New Folder" title="New Folder" width="54" height="48" hspace="2" vspace="0" border="0" onClick={createNewFolder} className="folderpointer" /></span></td>
                                }
                                {!props.disableCheckbox &&
                                    <td align="left" valign="top"><span title="Upload"><img src={ButtonUpload} alt="Upload" title="Upload" width="51" height="48" hspace="2" vspace="0" border="0" onClick={toggleUploadPopup} className="folderpointer" value={modal} /></span></td>
                                }
                                {!props.disableCheckbox &&
                                    <td align="left" valign="top"><span title="Download"><img src={BulkDownload} alt="Download" title="Download" width="51" height="48" hspace="2" vspace="0" border="0" onClick={toggleDownloadPopup} className="folderpointer" /></span></td>
                                }
                                {(localStorage.getItem('user.role') === 'Super Admin' || localStorage.getItem('user.role') === 'Admin') &&
                                    <td width="160" valign="top" align="left"><PeopleTwoToneIcon className="users-mi-icon folderpointer" onClick={navigateToUsers} /></td>
                                }
 
                            </tr>
                        </table>
                    </td>
                    <td align="left" valign="middle" width="60%" height="70">
                        {liveUrlSet == 1 &&
                            <table border='0' width='98%' cellSpacing="0" cellPadding="3" class="confirmation">
                                <tbody>
                                    <tr>
                                        <td>
                                            <strong>
                                                {mediaServerFileName} &nbsp;
                                            </strong>
                                            has been pushed to the live server <br />
                                        </td>
                                    </tr>
 
                                    <tr>
                                        <td>
                                            <strong> The live URL for this file &nbsp;
                                                {(curriculumUrl !== undefined) &&
                                                    <>
                                                        <span style={{ color: "red" }}>
                                                            SMS
                                                        </span>
                                                        <span>
                                                            &nbsp;or providing&nbsp;
                                                        </span>
                                                    </>
                                                }
                                                {(curriculumUrl !== undefined) &&
                                                    <span style={{ color: "red" }}>
                                                        open access,&nbsp;
                                                    </span>
                                                }is
                                                : </strong>
                                            <br />
                                            <span>
                                                <a href={`${mediaServerUrl}`} target="_blank" rel="noreferrer">{mediaServerUrl} </a>
 
                                            </span>
                                        </td>
                                    </tr>
                                    {(curriculumUrl !== undefined) &&
                                        <tr>
                                            <td>
                                                <strong> The live URL for this file, when using <span style={{ color: "red" }}>RUMBA</span> is : </strong>
                                                <br />
                                                <span>
                                                    <a href={`${curriculumUrl}`} target="_blank" rel="noreferrer">{curriculumUrl} </a>
                                                </span>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        }
                        {genericSuccessMessages?.message &&
                            <table border='0' width='98%' cellSpacing="0" cellPadding="3" class="confirmation">
                                <tbody>
                                    <tr>
                                        <td>
                                            {parse(genericSuccessMessages?.message)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        }
                        {mediaServerURLs?.error_message &&
                            <table border='0' width='98%' cellSpacing="0" cellPadding="3" class="confirmation">
                                <tbody>
                                    <tr>
                                        <td>
                                            {parse(mediaServerURLs?.error_message)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        }
                        {deletedFilesInfo?.message &&
                            <table border='0' width='98%' cellSpacing="0" cellPadding="3" class="confirmation">
                                <tbody>
                                    <tr>
                                        <td>
                                            {parse(deletedFilesInfo?.message)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        }
                    </td>
                </tr>
            </table>
 
 
            <span className="spacer"><br /></span>
 
            <table border="0" width="99%" cellSpacing="0" cellPadding="0">
                <tr>
                    <td className="info-box">
                        <span>{bannerMessage} </span>&nbsp;
                    </td>
                </tr><br></br>
                <tr align="left" valign="top" key="crumBox">
                    <td align="left" valign="middle" className="crumBox">
                        <strong>Current Location:</strong> {directoriesLink}
                    </td>
 
                </tr>
 
            </table>
            <div className="App">
                {modal && <Modal
                    displayModal={modal}
                    //closeModal={selectModal}
                    currentDir={props.currentPath}
                    onModalPopupStateChange={handleModalPopupStateChange}
                    controller={controller}
                ><Outlet /></Modal>
                }
            </div>
 
            <div>
                {bulkModal && <BulkUploadPopup
                    displayModal={bulkModal}
                    closeModal={toggleUploadPopup}
                    currentDir={props.currentPath}
                    onModalPopupStateChange={handleModalBulkUploadPopupStateChange}
                    controller={controller}
                ><Outlet /></BulkUploadPopup>
                }
            </div>
            <div>
                {bulkdownload && <BulkDownloadPopup
                    displayModal={bulkdownload}
                    closeModal={toggleDownloadPopup}
                    currentDir={props.currentPath}
                    onModalPopupStateChange={handleModalBulkDownloadPopupStateChange}
                    controller={controller}
                ><Outlet /></BulkDownloadPopup>
                }
            </div>
 
            <div>
 
                {newFolderName &&
                    <ReactModal
                        isOpen={newFolderName}
                        onRequestClose={closeCreateFolderModalPopup}
                        contentLabel="Create New Folder"
                        ariaHideApp={false}
                        style={customStyles}
                        shouldCloseOnOverlayClick={false}
                    >
                        <NewFolder
                            isOpen={newFolderName}
                            onRequestClose={closeCreateFolderModalPopup}
                            currentPath={props.currentPath}
                        />
                        <Outlet />
                    </ReactModal>
                }
            </div>
 
        </div>
    );
}
 
export default Banner;